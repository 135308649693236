/* Margin */
.m-auto {
  margin: 0 auto;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

/* Padding */
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.mt-5 {
  padding-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

/* Text color */
.text-black {
  color: black;
}

.text-white {
  color: white;
}

/* Background color */
.background-white {
  background-color: white;
}

.background-black {
  background-color: black;
}

.background-transparent {
  background-color: transparent;
}

/* Shadow */
.shadow-5-1 {
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
}

.shadow-10-1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.shadow-15-1 {
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

.shadow-5-3 {
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.shadow-10-3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.shadow-15-3 {
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
}

.shadow-5-5 {
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.shadow-10-5 {
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.shadow-15-5 {
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
}

.shadow-5-7 {
  box-shadow: 0 0 5px rgba(0, 0, 0, .7);
}

.shadow-10-7 {
  box-shadow: 0 0 10px rgba(0, 0, 0, .7);
}

.shadow-15-7 {
  box-shadow: 0 0 15px rgba(0, 0, 0, .7);
}

.width-fill {
  width: 100%;
}