@import 'styles/colors';

html {
  font-size: 17.5px;
    height  : 100%;
    overflow: hidden;
    position: relative;
  *:focus {
    outline: none;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

@font-face {
  font-family: NotoSans;
  font-style: normal;
  font-weight: normal;
  src: local('NotoSansCJKkr'), local('NotoSansCJKkr-Regular'), url('fonts/NotoSansCJKkr-Regular.otf') format('otf');
}

h1 {
  font-weight: normal;
}

a {
  color: $slate-grey;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  width: auto;
  height: auto;
}

body {
  touch-action: manipulation;
  margin: 0;
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height  : 100%;
  overflow: auto;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

//.MuiInputLabel-outlined.MuiInputLabel-shrink {
//  transform: translate(14px, 16px) scale(0.4) !important;
//  background-color: transparent;
//}
//
//.MuiInputLabel-outlined {
//  background-color: transparent;
//}
.MuiBottomNavigationAction-label {
  margin-top: 7px;
  color: #6c7688;
  letter-spacing: -0.33px;
  text-align: center;
  font-size: 13px !important;
}

.MuiBottomNavigationAction-label.Mui-selected {
  margin-top: 7px;
  font-size: 13px !important;
  font-weight: bold;
  color: $dark-slate-blue;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.rec.rec-dot,
.rec.rec-dot_active {
  border: none !important;
  padding: 0 4px;
  width: 1px;
  height: 7px;
  background-color: $place-holder;
  box-shadow: none !important;
}

.rec.rec-dot_active {
  background-color: $accent;
}

.rec.rec-arrow {
  display: none;
}

.rec.rec-carousel {
  margin-top: 16px;
  //height: 114px;

  img {
    max-width: 100%;
    width: auto;
  }
}

.rec.rec-pagination {
  margin-top: 0;
}
