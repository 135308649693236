@import "colors";

@mixin big-header {
  font-family: NotoSansCJKkr;
  font-size: 35px;
  letter-spacing: -0.88px;
  color: $text;
}

@mixin underLine {

  position: absolute;
  color: $slate-grey;
  @content;

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    border-bottom: 1px solid $slate-grey;
    right:0;
    bottom: -3px;
    left: 0;
  }
}
