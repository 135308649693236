@import url('https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css');
@import "./colors";
@import "./layout";

* {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
  outline: none;
  border: none;
}

body, html {
  font-family: 'Noto Sans KR', sans-serif;
}

a {
  color: $gray-third-color;
  text-decoration: none;
  font-size: 14px;
}

h1, .h1 {
  font-size: 20px;
}

h2, .h2 {
  font-size: 1.25rem;
}

h3, .h3 {
  font-size: 1rem;
}

h4, .h4 {
  font-size: .75rem;
}

h5, .h5 {
  font-size: .6rem;
}

h6, .h6 {
  font-size: 8px;
}

p, .p {
  font-size: 12px;
}

span, .span {
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6, p {
  line-height: 1.4;
}

hr {
  width: 100%;
  height: .1px;
  background-color: $divider-color;
  border: none;
}

.hr-vertical {
  width: .1px;
  height: 100%;
  background-color: $divider-color;
}

button {
  outline: none;
  border: none;
}

.light {
  font-weight: 200;
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 700;
}

.blue {
  color: $blue-primary-color;
}

.green {
  color: $green-secondary-color;
}

.red {
  color: $red-color;
}

.white {
  color: white;
}

.black {
  color: black;
}

.gray {
  color: $text-secondary-color;
}

.gray-primary {
  color: $gray-primary-color;
}

.gray-secondary {
  color: $gray-secondary-color;
}


.flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grid {
  display: grid;
}

.text-center {
  text-align: center;
  margin: 15px auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.input-container {
  display: flex;
  width: 100%;
  margin: 5px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid $gray-third-color;

  input {
    padding: 0;
    width: 100%;
    outline: none;
    color: black;
    font-size: 16px;
    border: none;
    font-weight: 900;

    &::placeholder {
      color: $gray-third-color;
      font-weight: 400;
    }
  }

  img {
    position: absolute;
    right: 25px;
    display: none;
  }

  img + img {
    margin-right: 25px;
  }

  &.changed {
    .input-clear, .input-view {
      display: block;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: stretch;

  input[type=checkbox] {
    display: none;

    //& + label {
    //  display: inline-block;
    //  cursor: pointer;
    //  line-height: 22px;
    //  padding-left: 22px;
    //  background: url("../../../_common/icons/checkbox-default-icon.svg") left/15px no-repeat;
    //}
    //
    //&:checked + label {
    //  background-image: url("../../../_common/icons/checkbox-checked-icon.svg");
    //}
  }
}