// From Strong, Heavy color
$accent: #8d4fff;
$primary: #1c3360;
$text : #1a1a1b;
$dark-slate-blue : #1c3360;
$bg: #eff2f5;
$slate-grey: #52525e;
$place-holder: #9a9a9e;
$silver: #d6dade;
$steel: #72808e;
$sunflower-yellow : #ffd109;
$white : #fff;
$red : #FF0000;
$parking :#09d046;

:export {
  accent: $accent;
  primary: $primary;
  text : $text ;
  slate-grey: $slate-grey;
  place-holder: $place-holder;
  silver: $silver;
  white : $white;
  dark-slate-blue : $dark-slate-blue;
  steel: $steel;
  red: $red;
  sunflower-yellow: $sunflower-yellow;
  bg: $bg;
  parking: $parking;
}
