@import "styles/_colors.scss";

.modalContainer {

}

.ModalContentContainer {
  width: 100vw;
  height: 100vh;
  padding: 26px 30px 0;
  background-color: $bg;
  .AssigneeAgreementContainer {
    .Header {
      height: 64px;
      display: flex;
      align-items: center;
    }

    .AgreementWrapper {
      height: 351px;
      border-radius: 12px;
      background-color: $white;
      overflow-y: auto;
    }

    .description {
      font-size: 17.5px;
      line-height: 1.54;
      letter-spacing: -0.44px;
      color: $place-holder;
    }
  }
}
