$gray-primary-color: #5E6367;
$gray-secondary-color: #72808E;
$gray-third-color: #999999;
$gray-forth-color: #EFF2F5;
$gray-fifth-color: #B2B9C3;
$skyblue-color: #E1F1FF;
$green-primary-color: #09D046;
$green-secondary-color: #08CF45;
$red-color: #EA5D5D;
$blue-primary-color: #007BFF;
$blue-secondary-color: #4276A5;
$text-primary-color: #1E4A64;
$text-secondary-color: #5A8CAA;
$text-third-color: #556F86;
$button-border-color: #265076;
$button-background-color: #2E6190;
$jaju-color: #458490;
$purple-primary-color: #5A6D9F;
$purple-secondary-color: #556F86;
$divider-color: #D6DDE3;
$term-text-color: #676767;