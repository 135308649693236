@import 'styles/_colors.scss';

.SettingContainer {
  background-color: $bg;
  height: 100vh;
  position: relative;

  .header {
    height: 64px;
    padding: 21px 17px;
    display: flex;
    align-items: center;
    font-size: 21.5px;
    font-weight: 500;
    letter-spacing: -0.54px;
  }

  .Wrapper {
    padding: 0 25px;

    .belong {
      display: flex;
      align-items: center;
      font-size: 21.5px;
      font-weight: bold;
      letter-spacing: -0.54px;
      height: 85px;

      .wapen {
        width: 50px;
        height: 50px;
        background-color: $white;
        border-radius: 100%;
        margin-right: 17px;
        border-bottom: 1px solid $silver;
      }
    }

    .item {
      height: 62px;
      font-size: 16.5px;
      letter-spacing: -0.41px;
      border-bottom: 1px solid $silver;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .button {
    position: absolute;
    left: 3.6%;
    max-width: calc(100% - 7.2%);
    bottom: 3.6%;
  }
}
