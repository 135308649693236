@import "variables";
@import "colors";
@import "mixins";

.headingTotal {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .todayCounts {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .monthlyCounts {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}